window.require = require
window.jQuery = window.$ = require('jquery')
var _ = require("underscore")
var Hammer = require('hammerjs')

require('page-editor').configure()

$('.tribar').click(function(evt) {
	evt.preventDefault()
	$('.nav').toggleClass('open')
})

$('.navbar a[href="' + window.location.pathname + '"]').addClass('current')

var scrollingNow = false

$(function() {
	$('body').addClass('page-loaded')
})

if($('.page.screens').length > 0) {
	
	$('.cell').eq(0).addClass('current')
	
	var goDown = function() {
		move(-1)
		
	}
	var goUp = function() {
		move(1)
		
	}

	var move = function(sign) {
		var perCell = 100 / $('.cell').length
		var now = parseInt($('.cells').attr('data-current')) || 0
		
		$('.cells').removeClass('current')
		var next = now + (sign * perCell)
		if(next > 0) {
			next = 0;
		}
		if(next > -95 && next <= 0) {
			var curCell = Math.round(Math.abs(next / perCell))
			$('.cell').removeClass('current').eq(curCell).addClass('current')
			
			$('.cells').css('transform', 'translateY(' + next + '%)')
			$('.cells').attr('data-current', next)
		}
		
		
	}

	$('body').on('keydown', function(evt) {
		if(evt.keyCode == 40) {
			goDown()
		}
		if(evt.keyCode == 38) {
			goUp()
		}
	})

	var handleMouseScrollevent = function(e) {

		if(!scrollingNow) {
			scrollingNow = true
			// cross-browser wheel delta
			var e = window.event || e; // old IE support
			var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)))
			if(delta < 0) {
				goDown()
			}
			else {
				goUp()
			}
			
			setTimeout(function() {
				scrollingNow = false
			}, 1000)
		}
		return false;	
	}

	var onMouseWheel = _.throttle(handleMouseScrollevent, 500)

	var scrollElement = document.documentElement
	if (scrollElement.addEventListener) {
		// IE9, Chrome, Safari, Opera
		scrollElement.addEventListener("mousewheel", onMouseWheel, false)
		// Firefox
		scrollElement.addEventListener("DOMMouseScroll", onMouseWheel, false)
	}
	// IE 6/7/8
	else {
		scrollElement.attachEvent("onmousewheel", onMouseWheel)
	}

	var stage = document.documentElement
	 
	// create a manager for that element 
	var mc = new Hammer.Manager(stage);
	 
	// create a recognizer 
	var Swipe = new Hammer.Swipe();
	 
	// add the recognizer 
	mc.add(Swipe);
	 
	// subscribe to events 
	mc.on('swipe', function(e) {
	    // do something cool 
		if(Math.abs(e.deltaY) > 80) {
			if(e.deltaY > 0) {
				goUp()
			}
			else {
				goDown()
			}
		}
	});
}




$(function() {
	$('.flow-sigma-square').addClass('show')	
})
